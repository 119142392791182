import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 32 32">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M24.469 17.453l-2.609 0.516 2.906 1.672c0.469 0.266 0.641 0.891 0.359 1.359s-0.891 0.641-1.359 0.359l-2.906-1.656 0.859 2.5c0.438 1.25-1.469 1.891-1.891 0.656l-1.594-4.688-4.234-2.438v4.891l3.25 3.719c0.875 0.984-0.641 2.312-1.5 1.313l-1.75-2v3.344c0 0.547-0.453 1-1 1s-1-0.453-1-1v-3.344l-1.75 2c-0.859 1-2.375-0.328-1.5-1.313l3.25-3.719v-4.891l-4.234 2.438-1.594 4.688c-0.422 1.234-2.328 0.594-1.891-0.656l0.859-2.5-2.906 1.656c-0.469 0.281-1.078 0.109-1.359-0.359s-0.109-1.094 0.359-1.359l2.906-1.672-2.609-0.516c-1.297-0.266-0.906-2.219 0.391-1.969l4.844 0.969 4.234-2.453-4.234-2.453-4.844 0.969c-0.063 0.016-0.141 0.016-0.203 0.016-1.188 0-1.359-1.75-0.187-1.984l2.609-0.516-2.906-1.672c-0.469-0.266-0.641-0.891-0.359-1.359 0.281-0.484 0.891-0.641 1.359-0.359l2.906 1.656-0.859-2.5c-0.438-1.25 1.469-1.891 1.891-0.656l1.594 4.688 4.234 2.438v-4.891l-3.25-3.719c-0.875-0.984 0.641-2.312 1.5-1.313l1.75 2v-3.344c0-0.547 0.453-1 1-1s1 0.453 1 1v3.344l1.75-2c0.859-1 2.375 0.328 1.5 1.313l-3.25 3.719v4.891l4.234-2.438 1.594-4.688c0.422-1.234 2.328-0.594 1.891 0.656l-0.859 2.5 2.906-1.656c0.469-0.281 1.078-0.109 1.359 0.359s0.109 1.094-0.359 1.359l-2.906 1.672 2.609 0.516c1.172 0.234 1 1.984-0.187 1.984-0.063 0-0.141 0-0.203-0.016l-4.844-0.969-4.234 2.453 4.234 2.453 4.844-0.969c1.297-0.25 1.687 1.703 0.391 1.969z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
